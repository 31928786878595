/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Linkable } from '@/components/blocks/Linkable';
import { PriceDisplay } from '@/components/blocks/PriceDisplay';
import { ProductImage } from '@/components/blocks/ProductImage';
import { ProgressIndicator } from '@/components/blocks/ProgressIndicator';
import { OrderItemQuantity } from '@/components/content/OrderItemTable/parts/Quantity';
import { OrderItemTableRowData } from '@/components/content/OrderItemTable/parts/Table';
import { orderItemTableItemDetailsImageMiniSX } from '@/components/content/OrderItemTable/styles/orderItemTableItemDetailsImageMini';
import { useOrderItemTableRow } from '@/data/Content/OrderItemTable';
import { useLocalization } from '@/data/Localization';
import { ContentContext } from '@/data/context/content';
import { Stack, Typography } from '@mui/material';
import { FC, useContext } from 'react';

const EMPTY_PRICE = { orderItemPrice: '', currency: '' };
export const OrderItemItemDetailsMini: FC = () => {
	const {
		details,
		itemDetails,
		price: { orderItemPrice = '', currency = '' } = EMPTY_PRICE,
		freeGift,
	} = useContext(ContentContext) as OrderItemTableRowData & ReturnType<typeof useOrderItemTableRow>;
	const { name, thumbnailRaw, href, partNumber, prices, loading } = details;
	const labels = useLocalization('OrderItemTable').Labels;
	const price = Number(orderItemPrice);

	if (loading) {
		return <ProgressIndicator />;
	}
	return (
		<Stack direction="row" alignItems="flex-start" spacing={2}>
			{thumbnailRaw ? (
				// TODO Fix potential accessibility issue with img alt name being the same as adjacent link name
				<Linkable href={href} id={href} data-testid={href}>
					<ProductImage
						{...{
							src: thumbnailRaw,
							alt: labels.ProductThumbnail.t(),
							isThumbnail: true,
							sx: orderItemTableItemDetailsImageMiniSX,
						}}
					/>
				</Linkable>
			) : null}
			<Stack direction="column" alignItems="flex-start" sx={{ flexGrow: 1 }}>
				{itemDetails?.xitem_field2 ? (
					<Typography>{name}</Typography>
				) : (
					<Linkable href={href} id={href} data-testid={href}>
						<Typography>{name}</Typography>
					</Linkable>
				)}

				<Typography>
					{itemDetails?.xitem_field2 ? itemDetails?.xitem_field2 : partNumber}
				</Typography>
				{freeGift ? <Typography variant="overline">{labels.Gift.t()}</Typography> : null}
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					width="100%"
					mt={1}
				>
					<OrderItemQuantity mini={true} readOnly={true} />
					{prices ? (
						<Typography>
							<PriceDisplay currency={currency} min={price} />
						</Typography>
					) : null}
				</Stack>
			</Stack>
		</Stack>
	);
};
