/* eslint-disable complexity */
/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Linkable } from '@/components/blocks/Linkable';
import { ProductImage } from '@/components/blocks/ProductImage';
import { ProgressIndicator } from '@/components/blocks/ProgressIndicator';
import { BackorderedModal } from '@/components/content/OrderItemTable/parts/BackorderedModal';
import { OrderItemPrice } from '@/components/content/OrderItemTable/parts/Price';
import { OrderItemQuantity } from '@/components/content/OrderItemTable/parts/Quantity';
import { OrderItemTableRowData } from '@/components/content/OrderItemTable/parts/Table';
import { orderItemTableItemDetailsImageSX } from '@/components/content/OrderItemTable/styles/orderItemTableItemDetailsImage';
import { ProductXRef } from '@/components/content/ProductDetails/parts/XRef';
import { useOrderItemTableRow } from '@/data/Content/OrderItemTable';
import { useLocalization } from '@/data/Localization';
import { ContentContext } from '@/data/context/content';
import { Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useContext, useMemo } from 'react';

export const OrderItemItemDetailsCompact: FC<{
	readOnly?: boolean;
}> = ({ readOnly = false }) => {
	const orderItemTableNLS = useLocalization('OrderItemTable');
	const {
		details,
		price,
		itemDetails,
		freeGift,
		quantity: { quantity },
	} = useContext(ContentContext) as OrderItemTableRowData & ReturnType<typeof useOrderItemTableRow>;
	const {
		partNumber,
		name,
		color,
		thumbnail,
		href,
		attributes,
		loading,
		xrefPartNumber,
		extInventoryQuantity,
		checkInventory,
	} = details;
	const filteredAttributes = attributes.filter((item) => item.usage === 'Defining');
	const partNumberShort = partNumber.length > 20 ? partNumber.substring(0, 20) + '...' : partNumber;
	const isBackordered = useMemo(() => {
		if (
			checkInventory &&
			typeof extInventoryQuantity === 'number' &&
			quantity > extInventoryQuantity
		) {
			return true;
		} else {
			return false;
		}
	}, [checkInventory, extInventoryQuantity, quantity]);
	if (loading) {
		return <ProgressIndicator />;
	}
	return (
		<Stack direction="row" alignItems="flex-start" spacing={2}>
			{thumbnail ? (
				<Linkable href={href} id={href} data-testid={href}>
					<ProductImage
						{...{
							src: thumbnail,
							alt: orderItemTableNLS.Labels.ProductThumbnail.t(),
							isThumbnail: true,
							sx: orderItemTableItemDetailsImageSX,
						}}
					/>
				</Linkable>
			) : null}
			<Stack direction="column" alignItems="flex-start">
				{itemDetails?.xitem_field2 ? (
					<Typography variant="h6" data-testid="orderItem-name" id="orderItem-name">
						{color ? `${name}, ${color}` : name}
					</Typography>
				) : (
					<Linkable href={href} id={href} data-testid={href}>
						<Typography variant="h6" data-testid="orderItem-name" id="orderItem-name">
							{color ? `${name}, ${color}` : name}
						</Typography>
					</Linkable>
				)}
				{isBackordered ? <BackorderedModal /> : null}
				{!isEmpty(xrefPartNumber) ? (
					<Typography color="textError.error">
						<ProductXRef xrefPartNumber={xrefPartNumber} />
					</Typography>
				) : null}
				<Typography data-testid="orderItem-partNumber" id="orderItem-partNumber">
					{`${orderItemTableNLS.SKULabel.t()} ${
						itemDetails?.xitem_field2 ? itemDetails?.xitem_field2 : partNumberShort
					}`}
				</Typography>
				<Stack spacing={0.25}>
					{filteredAttributes.map(({ identifier, name, values }, i: number) => (
						<Stack
							key={`order-item-attribute-${i}`}
							direction="row"
							spacing={0.5}
							alignItems="center"
						>
							<Typography
								variant="body1"
								component="span"
								id="orderItem-attributeName"
								data-testid="orderItem-attributeName"
							>
								{`${name}:`}
							</Typography>
							{values.map(({ value }, i: number) => (
								<Typography
									key={`order-item-attribute-${identifier}-${i}`}
									variant="body1"
									component="span"
									data-testid="orderItem-attributeValue"
									id="orderItem-attributeValue"
								>
									{value}
								</Typography>
							))}
						</Stack>
					))}
				</Stack>

				{freeGift ? (
					<Typography variant="overline">{orderItemTableNLS.Labels.Gift.t()}</Typography>
				) : null}

				<Stack spacing={0.25}>
					{readOnly || freeGift ? (
						<Stack direction="row" spacing={0.5} alignItems="center">
							<Typography
								variant="body2"
								component="span"
								data-testid="orderItem-quantity"
								id="orderItem-quantity"
							>
								{`${orderItemTableNLS.Labels.Quantity.t()}:`}
							</Typography>
							<OrderItemQuantity readOnly={readOnly} />
						</Stack>
					) : (
						<OrderItemQuantity />
					)}
					{price ? (
						<Stack direction="row" spacing={0.5} alignItems="center">
							<Typography variant="body2" data-testid="orderItem-price" id="orderItem-price">
								{`${orderItemTableNLS.Labels.Price.t()}:`}
							</Typography>
							<OrderItemPrice />
						</Stack>
					) : null}
				</Stack>
			</Stack>
		</Stack>
	);
};
