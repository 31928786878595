import { useLocalization } from '@/data/Localization';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Link } from '@mui/material';
import { FC, useState } from 'react';

export const BackorderedModal: FC<{ compact?: boolean }> = ({ compact = false }) => {
	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);
	const OrderItemNLS = useLocalization('OrderItemTable');
	const ActionsNSL = useLocalization('Actions');

	return (
		<>
			<Link
				component="button"
				sx={{ color: 'primary.main', '&:hover': { color: 'primary.dark' } }}
				onClick={() => setOpen(!open)}
			>
				{compact ? OrderItemNLS.BackorderedButtonCompact.t() : OrderItemNLS.BackorderedButton.t()}
			</Link>
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
				<Box display="flex" justifyContent="flex-end" pt={1} pr={1}>
					<IconButton aria-label={ActionsNSL.Close.t()} onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</Box>
				<DialogContent sx={{ pt: 0 }}>{OrderItemNLS.BackorderedModalContent.t()}</DialogContent>
				<DialogActions sx={{ justifyContent: 'flex-start', p: '20px 24px' }}>
					<Button variant="outlined" onClick={handleClose}>
						{ActionsNSL.Close.t()}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
